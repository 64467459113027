import { call, put } from 'redux-saga/effects';
import { ApiResponse } from 'apisauce';

import ReportReasonsActions from '../Redux/ReportReasonsRedux';
import ToasterActions from '../Redux/ToasterRedux';
import { extractErrorForToaster } from '../Services/ApiErrorHelpers';

export default function* getReportReasons(api: any) {
  const response: ApiResponse<any> = yield call(api.getPostReportReasons);

  if (response.ok) {
    yield put(ReportReasonsActions.getReportReasonsSuccess(response.data.data));
  } else {
    yield put(ReportReasonsActions.getReportReasonsFailure(extractErrorForToaster(response)));
    yield put(ToasterActions.showToasterError(extractErrorForToaster(response)));
  }
}
