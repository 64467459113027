import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  showToaster: ['message'],
  showToasterError: ['message'],
  resetErrors: null,
});

export const ToasterTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  open: false,
  message: null,
  severity: null,
});

/* ------------- Reducers ------------- */
const showToaster = (state: any, { message }: any) => ({
  ...state,
  open: true,
  message,
  severity: null,
});

const showToasterError = (state: any, { message }: any) => ({
  ...state,
  open: true,
  message,
  severity: 'error',
});
// A `Types.RESET_ERRORS` action is triggered which will erase errors
// from the different stores
const resetErrors = () => INITIAL_STATE;

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SHOW_TOASTER]: showToaster,
  [Types.SHOW_TOASTER_ERROR]: showToasterError,
  [Types.RESET_ERRORS]: resetErrors,
});
