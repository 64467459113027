import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

export const INITIAL_STATE = Immutable({
  reasons: null,
  error: null,
  fetching: false,
});

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  getReportReasonsRequest: [],
  getReportReasonsSuccess: ['payload'],
  getReportReasonsFailure: ['payload'],
});

export const ReportReasonsTypes = Types;
export default Creators;

const getReportReasonsRequest = (state: any) => ({
  ...state,
  fetching: true,
});

const getReportReasonsSuccess = (state: any, { payload }: any) => ({
  ...state,
  reasons: payload,
  fetching: false,
});

const getReportReasonsFailure = (state: any, { payload }: any) => ({
  ...state,
  fetching: false,
  error: payload,
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_REPORT_REASONS_REQUEST]: getReportReasonsRequest,
  [Types.GET_REPORT_REASONS_SUCCESS]: getReportReasonsSuccess,
  [Types.GET_REPORT_REASONS_FAILURE]: getReportReasonsFailure,
});
