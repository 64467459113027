import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Snackbar, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useIntl } from 'react-intl';
import { useSearchParams } from 'react-router-dom';

import ToasterActions from '../../Redux/ToasterRedux';

function Toaster() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [search, setSearch] = useSearchParams();
  const toasterFromSearch = {
    message: search.get('toast'),
    severity: search.get('severity') || 'info',
  };
  const toasterFromStore = useSelector((state: any) => state.toaster);
  const { message, severity } = toasterFromSearch.message ? toasterFromSearch : toasterFromStore;
  const open = toasterFromStore.open || Boolean(toasterFromSearch.message);

  let messageToDisplay = message;

  const closeToaster = () => {
    if (toasterFromSearch.message) {
      // remove toast params
      setSearch({});
    } else {
      dispatch(ToasterActions.resetErrors());
    }
  };

  // the api returns an array of errors
  if (message instanceof Array) {
    messageToDisplay = message[0].title;
  }

  // If an object with a key `id` is received, like when we want to translate
  // a message, we fetch the corresponding translation to be displayed
  if (message && message.id) {
    messageToDisplay = intl.formatMessage(message, message.values);
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={open}
      autoHideDuration={6000}
      onClose={closeToaster}
      ContentProps={{
        sx: severity === 'error' ? { backgroundColor: 'error.main' } : {},
      }}
      message={<span id="client-snackbar" dangerouslySetInnerHTML={{ __html: messageToDisplay }} />}
      action={
        <IconButton aria-label="Close" color="inherit" onClick={closeToaster}>
          <CloseIcon fontSize="small" />
        </IconButton>
      }
      data-testid="toaster-content"
    />
  );
}

export default Toaster;
