import React from 'react';
import { Button, Menu } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import Avatar from '../DesignSystem/Avatar';
import ProfileMenuItems from './ProfileMenuItems';
import { cloudinaryImageUrl, CloudinaryConfig } from '../../Services/Cloudinary';

function ProfileMenu({ fullName, initials, userPictureS3Key, filename }: any) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const openMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button size="large" color="inherit" onClick={openMenu} endIcon={<ArrowDropDownIcon />}>
        <Avatar
          src={cloudinaryImageUrl(userPictureS3Key, CloudinaryConfig.AVATAR_40, filename)}
          alt={fullName}
          placeholderType="user"
          fullName={fullName}
          initials={initials}
          size={40}
        />
      </Button>

      <Menu
        id="profile-menu-actions"
        role="list"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={closeMenu}
        MenuListProps={{ component: 'nav', disablePadding: true }}
      >
        <ProfileMenuItems onClick={closeMenu} />
      </Menu>
    </>
  );
}

export default ProfileMenu;
