import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  getMyEntitiesRequest: [],
  getMyEntitiesSuccess: ['payload'],
  getMyEntitiesFailure: ['errors'],

  updatePasswordRequest: ['currentPassword', 'newPassword'],
  updatePasswordSuccess: [],
  updatePasswordFailure: ['errors'],
  updatePasswordReset: [],

  createPasswordRequest: ['password'],
  createPasswordSuccess: [],
  createPasswordFailure: ['errors'],

  resetErrors: [],
});

export const ProfileTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  entities: [],
  posts: [],
  page: 0,
  currentlyLoadingPage: 0,
  isFetching: false,
  errors: null,
  updatePassword: {
    errors: null,
    isFetching: null,
    success: null,
  },
  createPassword: {
    errors: null,
    isFetching: null,
    success: null,
  },
  myContributionsPaging: null,
});

/* ------------- Reducers ------------- */

//
// GET THE ENTITIES I SUBSCRIBED TO
//
const getMyEntitiesRequest = (state: any) => ({
  ...state,
  isFetching: true,
  errors: null,
});

const getMyEntitiesSuccess = (state: any, { payload }: any) => ({
  ...state,
  entities: payload.data,
  isFetching: false,
});

const getMyEntitiesFailure = (state: any, { errors }: any) => ({
  ...state,
  isFetching: false,
  errors,
});

//
// update password
//
const updatePasswordRequest = (state: any) => ({
  ...state,

  updatePassword: {
    isFetching: true,
    errors: null,
    success: null,
  },
});
const updatePasswordSuccess = (state: any) => ({
  ...state,

  updatePassword: {
    isFetching: false,
    errors: null,
    success: true,
  },
});
const updatePasswordFailure = (state: any, { errors }: any) => ({
  ...state,

  updatePassword: {
    errors,
    isFetching: false,
    success: false,
  },
});
const updatePasswordReset = (state: any) => ({
  ...state,
  updatePassword: INITIAL_STATE.updatePassword,
  createPassword: INITIAL_STATE.createPassword,
});

//
// update password
//
const createPasswordRequest = (state: any) => ({
  ...state,

  createPassword: {
    isFetching: true,
    errors: null,
    success: null,
  },
});
const createPasswordSuccess = (state: any) => ({
  ...state,

  createPassword: {
    isFetching: false,
    errors: null,
    success: true,
  },
});
const createPasswordFailure = (state: any, { errors }: any) => ({
  ...state,

  createPassword: {
    errors,
    isFetching: false,
    success: false,
  },
});

//
// RESET ERRORS
//
const resetErrors = (state: any) => ({
  ...state,
  errors: INITIAL_STATE.errors,
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_MY_ENTITIES_REQUEST]: getMyEntitiesRequest,
  [Types.GET_MY_ENTITIES_SUCCESS]: getMyEntitiesSuccess,
  [Types.GET_MY_ENTITIES_FAILURE]: getMyEntitiesFailure,

  [Types.UPDATE_PASSWORD_REQUEST]: updatePasswordRequest,
  [Types.UPDATE_PASSWORD_SUCCESS]: updatePasswordSuccess,
  [Types.UPDATE_PASSWORD_FAILURE]: updatePasswordFailure,
  [Types.UPDATE_PASSWORD_RESET]: updatePasswordReset,

  [Types.CREATE_PASSWORD_REQUEST]: createPasswordRequest,
  [Types.CREATE_PASSWORD_SUCCESS]: createPasswordSuccess,
  [Types.CREATE_PASSWORD_FAILURE]: createPasswordFailure,

  [Types.RESET_ERRORS]: resetErrors,
});
