import { call, put } from 'redux-saga/effects';

import AuthActions from '../Redux/AuthRedux';
import { currentLocale, setCurrentLocale } from '../I18n';

export default function* startup() {
  yield call(setCurrentLocale, currentLocale);

  yield put(AuthActions.getProfileRequest());
}
