import React from 'react';
import {
  Dialog as MUIDialog,
  DialogProps as MuiDialogProps,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { useIntl } from 'react-intl';

import useWidth from '../../../Hooks/useWidth';
import DialogTitle from './DialogTitle';

interface DialogProps extends MuiDialogProps {
  children: any;
  id: string;
  label: string;
  title: any;
  onClose: any;
  disableGutters?: boolean;
  dismissible?: boolean;
  variant?: 'small' | 'medium' | 'large';
  actions?: JSX.Element;
  keepMounted?: boolean;
}

function Dialog({
  children,
  id,
  label,
  title,
  actions,
  onClose,
  disableGutters = false,
  dismissible = true,
  ...rest
}: DialogProps) {
  const { locale } = useIntl();
  const isMobile = useWidth('down', 'sm');
  const handleClose = () => {
    if (dismissible) {
      onClose();
    }
  };
  const dir = locale === 'ar-MA' ? 'rtl' : 'ltr';

  return (
    <MUIDialog
      fullScreen={isMobile}
      aria-labelledby={label}
      onClose={handleClose}
      fullWidth
      dir={dir}
      {...rest}
    >
      <DialogTitle id={id} dismissible={dismissible} content={title} onClose={onClose} dir={dir} />

      <DialogContent sx={{ ...(disableGutters && { p: 0 }) }}>{children}</DialogContent>

      {actions && <DialogActions sx={{ p: 3 }}>{actions}</DialogActions>}
    </MUIDialog>
  );
}

export default Dialog;
